const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/6.png",
    text: "6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/7.png",
    text: "7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/8.png",
    text: "8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/9.png",
    text: "9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/10.png",
    text: "10",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/11.png",
    text: "11",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/12.png",
    text: "12",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/arravaliclinic/13.png",
    text: "13",
  },
];

export default data;
