import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Arravali Hair Transplant Clinic, Vasant Vihar",
  address: "Vasant Vihar",
  client: "Arravali Hair Transplant Clinic",
  area: "2000 Sq. Ft.",
  project: "Interior design for hair transplant clinic",
  category: "Commercial | Interior",
  status: "Completed",
  backlink: "/projects/commercial/",
};

export default class Projectdentalclinicvasantvihar extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Arravali Hair Transplant Clinic, Vasant Vihar"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore the bespoke décor and interior design delivered for a leading clinic located in Delhi."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
